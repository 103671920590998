import "./RLD_Services.css";

import ServiceCard from "../RLD_Services/ServiceCard";

import previz from "../../images/ServicePhotos/previz.jpg";
import progLX from "../../images/ServicePhotos/progLX.png";
import progVid from "../../images/ServicePhotos/progVid.jpg";
import tcPbk from "../../images/ServicePhotos/tc-pbk.png";
import laserWeb from "../../images/ServicePhotos/laser_web.jpg";
import showOp from "../../images/ServicePhotos/show_op.jpg";
import renders from "../../images/ServicePhotos/renders.jpg";
import paperwork from "../../images/ServicePhotos/paperwork.jpg";
import design from "../../images/ServicePhotos/design.jpg";
import tech from "../../images/ServicePhotos/tech.jpg";

function RLD_Services() {
  return (
    <div
      id="services"
      className="bg-neutral-900 flex flex-col justify-center items-center  min-h-[95vh] md:poly-rot"
    >
      <div className="container flex flex-col justify-center items-start">
        <div className="m-4 mt-4 md:mt-10">
          <h2 className="text-4xl font-extrabold">Here's what</h2>
          <h2 className="text-3xl font-bold text-green-50">
            We can do for you.
          </h2>
        </div>
        <div className="rows">
          {/* Row 1 */}
          <div className=" row2 grid grid-cols-1 md:grid-cols-3 justify-between items-between">
            {/* card 1 */}
            <ServiceCard
              service={"Festival, Tour, &"}
              service2={"Stage Design"}
              photo={design}
              alt_info={"This is a photo"}
            />
            {/* card 2 */}
            <ServiceCard
              service={"Vectorworks, Plots, &"}
              service2={"Technical Paperwork"}
              photo={paperwork}
              alt_info={"This is a photo"}
            />
            {/* card 3 */}
            <ServiceCard
              service={"Stage Concepts"}
              service2={"& Renders"}
              photo={renders}
              alt_info={"This is a photo"}
            />
          </div>
          {/* Row 2 */}
          <div className=" row2 grid grid-cols-1 md:grid-cols-3 justify-between items-between">
            {/* card 1 */}
            <ServiceCard
              className=""
              service={"Lighting"}
              service2={"Programming"}
              photo={progLX}
              alt_info={"This is a photo"}
            />
            {/* card 2 */}
            <ServiceCard
              service={"Laser"}
              service2={"Programming"}
              photo={laserWeb}
              alt_info={"This is a photo"}
            />
            {/* card 3 */}
            <ServiceCard
              service={"Video"}
              service2={"Programming"}
              photo={progVid}
              alt_info={"This is a photo"}
            />
          </div>
          {/* Row 3 */}
          <div className=" row2 grid grid-cols-1 md:grid-cols-3 justify-between items-between pb-20">
            {/* card 1 */}
            <ServiceCard
              className=""
              service={"Technical"}
              service2={"Direction"}
              photo={tech}
              alt_info={"This is a photo"}
            />
            {/* card 2 */}
            <ServiceCard
              service={"Show"}
              service2={"Operation"}
              photo={showOp}
              alt_info={"This is a photo"}
            />
            {/* card 3 */}
            <ServiceCard
              service={"Timecode &"}
              service2={"Playback Systems"}
              photo={tcPbk}
              alt_info={"This is a photo"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RLD_Services;
