import "./Navbar.css";
import RLD_Logo from "../../images/Logo/RLD Square logo 500x500 nc.png";
import {
  Link,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

function Navbar() {
  return (
    <div className="bg-zinc-900 flex flex-col justify-center items-center min-h-[5vh] fixed w-full z-20">
      <div className="container px-4 md:px-0 flex justify-between items-center">
        <img
          onClick={scroll.scrollToTop}
          className="h-[5vh] pl-2"
          src={RLD_Logo}
          alt="RLD Logo"
        />

        <div className="flex items-center h-[5vh]">
          <div className="flex flex-col justify-center items-center navButton">
            <Link
              to="services"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              className="px-2 text-green-50 no-underline md:text-2xl"
            >
              Services
            </Link>
            <div className="bg-green-500 h-[.25vh] w-10/12 nav-line"></div>
          </div>
          <div className="flex flex-col justify-center items-center navButton">
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              className="px-2 text-green-50 no-underline md:text-2xl"
            >
              Contact
            </Link>
            <div className="bg-green-500 h-[.25vh] w-10/12 nav-line"></div>
          </div>
        </div>
      </div>
      <div className="bg-green-500 h-[.5vh] w-full"></div>
    </div>
  );
}

export default Navbar;
