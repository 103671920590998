import "./CoverSection.css";
import Logo from "../../images/Cover/Logo_Basic_green.png";
import More from "../../images/Cover/downarrow.png";

import {
  Link,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

function CoverSection() {
  return (
    <div className="flex justify-center items-center  min-h-[100vh] pt-[5vh] bg-[url('./images/Cover/BC_1.jpg')] bg-cover bg-center">
      <div className="flex flex-col h-[80vh] justify-center items-center py-20">
        <h1 className="w-4/5 max-w-6xl md:max-w-screen-2xl h-scale flex flex-col justify-center items-center">
          <img
            className="w-4/5 max-w-6xl md:max-w-screen-2xl h-scale"
            src={Logo}
          />
        </h1>
        <Link
          to="services"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
        >
          <img className="w-16 md:w-32 h-scale" src={More} />
        </Link>
      </div>
    </div>
  );
}

export default CoverSection;
