import "./Contact.css";
import React, { useRef, useState } from "react";

import emailjs from "@emailjs/browser";

function Contact() {
  const form = useRef();

  const [emailStatus, setEmailStatus] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_e5qw9hb",
        "template_6yoqsdz",
        form.current,
        "UfwEUAKRq0n36k_fo"
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmailStatus("Message Sent Successfully!");
        },
        (error) => {
          console.log(error.text);
          setEmailStatus("Message Not Sent Successfully!");
        }
      );
  };
  return (
    <div
      id="contact"
      className="bg-neutral-900 flex flex-col justify-center items-center  min-h-[90vh] bg-[url('./images/ServicePhotos/bg_1.jpg')]  bg-cover bg-center poly-rot2"
    >
      <div className="container card-full w-11/12">
        <div className="ContactHeader p-2">
          <h1 className="text-4xl font-extrabold">Let's get in touch.</h1>
          <a className="text-2xl" href="mailto: info@RareLifeDesign.com">
            info@RareLifeDesign.com
          </a>
          <p className="text-2xl pt-4" id="message-status">
            {emailStatus}
          </p>
        </div>

        <form className="FormDiv flex flex-col" ref={form} onSubmit={sendEmail}>
          <div className="InfoDiv flex flex-col p-2 mt-4">
            <input
              className="mb-2 md:w-1/3"
              type="text"
              name="name"
              placeholder="Name"
            />
            <input
              className="mb-2 md:w-1/3"
              type="text"
              name="email"
              placeholder="Email"
            />
            <input
              className=""
              type="text"
              name="subject"
              placeholder="Subject"
            />
          </div>
          <div className="MessageDiv flex flex-col px-2">
            <textarea
              className="min-h-[40vh] mb-2"
              name="message"
              placeholder="Message..."
            ></textarea>
            <input
              className="bg-green-500 border-4 mt-2 text-neutral-900 hover:bg-neutral-700 hover:text-green-500"
              type="submit"
              value="Submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
