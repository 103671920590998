function ServiceCard(serviceProps) {
  return (
    <div className="card-full">
      <h1>{serviceProps.service}</h1>
      <h1 className="text-green-50">{serviceProps.service2}</h1>

      <div className="card-image rounded-lg">
        <img
          className=""
          src={serviceProps.photo}
          alt={serviceProps.alt_info}
        />
      </div>
    </div>
  );
}

export default ServiceCard;
