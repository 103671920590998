import "./App.css";
import { useRef } from "react";
import Navbar from "./components/Navbar/Navbar";
import CoverSection from "./components/CoverSection/CoverSection";
import RLD_Services from "./components/RLD_Services/RLD_Services";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Test from "./components/TestArea/test";

function App() {
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <Navbar />
      <CoverSection />
      <RLD_Services ref={ref} handleClick={handleClick} />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
