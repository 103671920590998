import "./Footer.css";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="bg-zinc-900 flex flex-col justify-between items-center  min-h-[5vh]">
      <div className="bg-green-500 h-[.5vh] w-full"></div>
      <div className="min-h-[4.5vh] flex flex-col justify-center items-center">
        <p className="pb-2 font-light text-lg md:text-3xl">
          © {currentYear} Rare Life Design
        </p>
      </div>
    </div>
  );
}

export default Footer;
